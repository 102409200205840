.categories{
  background-color: #f1f1f1;
  position: relative;
}
.links-container{
  border: 5px solid $white-color;
  width: 80%;
  @media only screen and (min-width: 768px){
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 50%);
    left: 50%;
  }
  .col-link{
    width: 50%;
    @media only screen and (max-width: 768px){
      width: 100%;
    }
  }
  .links-link{
    font-weight: 700;
  }
  .active-link{
    background-color: $hover-link-background;
    color: $white-color;
  }
  .non-active-link{
    background-color: #d8d8d8;
    color: #7d7d7d;
    &:hover{
      background-color: $hover-link-background;
      color: $white-color;
    }
  }
}
.category-header{
  background-color: $white-color;
  color: $white-color;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  position: relative;
  z-index: 2;
  background-size: contain;
  &:before {
    content: "";
    position: absolute;
    right: 0;
    width: 100%;
    bottom: 0;
    top: 0;
    z-index: -1;
    opacity: .9;
    background: linear-gradient(to right,#ff482a 60%,rgba(0, 0, 0, 0) 100%);
  }
}
.modal{
  top: 120px;
}

.yes{
  font-weight: 600;
  color: $yes;
}
.no{
  font-weight: 600;
  color: $no;
}
.category-title{
  @extend .oswald;
  font-weight: 700;
}
.category-item{
  position: relative;
  background-color: $white-color;
  border-right: 1px solid #f1f1f1;
  color: $main-color;
  font-weight: 700;
  &:not(.not-hover):hover{
    color: $hover-link-background;
  }
  a{
    color: $main-color;
    &:hover{
      color: $hover-link-background;
    }
  }
  .category-bridge{
    font-weight: 400;
    color: $error-color;
    @include fontSize(10px);
  }
  .group{
    position: absolute;
    top: 50%;
    right: 7.5px;
    transform: translateY(-50%);
  }
  .up-group{
    transform: translateY(-50%) rotateX(180deg);
  }
  .product-image{
    height: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    width: 100px;
  }
  @include fontSize(12px);
  .product-title{
    @extend .oswald;
    @include fontSize(24px);
    font-weight: 400;
    color: $main-color;
  }
  .product-price{
    @include fontSize(16px);
    color: $hover-link-background;
    span{
      font-weight: 400;
      @include  fontSize(14px);
    }
  }
}
.category-model{
  border-left: 2px solid #f94629;
  .product-image{
    max-height: 100px;
  }
}
.product-slider-nav{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .arrow{
    &:hover{
      cursor: pointer;
    }
  }
}
.no-similar{
  color: #c3c3c3;
  @include fontSize(24px);
  font-weight: 400;
  background-color: #e8e8e8;
}

.category-header-description{
  img{
    margin: 15px;
  }
  a{
    color: $main-color;
    &:hover{
      color: $white-color;
      text-decoration: underline;
    }
  }
}