.product-single{
  .product-title-block{
    position: relative;
    border-bottom: 1px solid $main-color;
  }
  .product-image{
    height: 40vh;
  }
  .product-link{
    background-image: url('/assets/wagielektroniczne/img/sites/product-link.png');
    background-repeat: no-repeat;
    background-position: center right;
  }
  .product-name{
    @include fontSize(30px);
    @extend .oswald;
    color: $main-color;
    text-transform: uppercase;
  }
  .category-title{
    @include fontSize(12px);
    font-weight: 500;
    color: #959595;
    text-decoration: underline;
  }

  .product-price{
    color: $hover-link-background;
    @include fontSize(16px);
    font-weight: 500;
    span{
      font-weight: 400;
      @include  fontSize(14px);
    }
  }
}
.single-product{
  background: $white-color;
}

.product-functions{
  @include fontSize(14px);
  color: #464646;
  font-weight: 500;
}

.new-product-block{
  .product-title-block{
    position: relative;
  }
  .product{
    border: 2px solid #ebebeb;
  }
  .product-url{
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    height: 250px;
  }

  .pagination{
    background-color: #f1f1f1;
    margin-left: 15px;
    cursor: pointer;
    .prev{
      background-image: url(/assets/wagielektroniczne/img/sites/arrow-left.png);
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
    .next{
      background-image: url(/assets/wagielektroniczne/img/sites/arrow-right.png);
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }

  .product-bridge{
    font-weight: 400;
    color: $error-color;
    @include fontSize(10px);
  }

  .product-title{
    @include fontSize(16px);
    @extend .oswald;
    color: $main-color;
  }
  .product-link{
    background-image: url('/assets/wagielektroniczne/img/sites/mini-product-link.png');
    background-repeat: no-repeat;
    background-position: 95% 50%;
  }
  .product-price{
    color: $hover-link-background;
    @include fontSize(18px);
    font-weight: 700;
    span{
      font-weight: 400;
      @include  fontSize(16px);
    }
  }
}
.new-product-title-block{
  border-bottom: 2px solid $main-color;

  .new-product-title{
    @extend .oswald;
    @include fontSize(24px);
  }

}

.category{
  border-bottom: 1px solid #e4e4e4;
  .category-item{
    border-left: 1px solid #e4e4e4;
  }
  .category-link{
    @extend .oswald;
    @include fontSize(14px);
    color: $main-color;
    font-weight: 400;
  }
}

.search{
  display: none;
  background-color: #f1f1f1;
  position: fixed;
  top: 76px;
  left: 0;
  width: 100%;
  z-index: 2000;
  @media only screen and (max-width: 992px){
    width: calc(100% - 300px);
    left: 300px;
    top: 0;
  }
  .search-input{
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #e1e1e1;
    background-color: #f1f1f1;
    color: $main-color;
    font-weight: 700;
  }
  .product-title{
    @extend .oswald;
    @include fontSize(14px);
    color: $main-color;
  }
  .product-price{
    @include fontSize(14px);
    font-weight: 700;
    color: $hover-link-background;
    span{
      font-weight: 400;
      @include  fontSize(12px);
    }
  }
}

.border-bottom{
  border-bottom: 3px solid #e5e5e5;
}
#product{
  .product-title{
    @extend .oswald;
    @include fontSize(48px);
    font-weight: 400;
  }
  .product-description{
    color: #464646;
  }
  .product-image{
    @media only screen and (max-width: 992px){
      height: 42vh;
    }
  }
  .product-price{
    @include fontSize(24px);
    font-weight: 700;
    color: $hover-link-background;
    span{
      font-weight: 400;
      @include  fontSize(16px);
    }
  }
}
.product-image{
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.product-user{
  color: $main-color;
  &:hover{
    text-decoration: underline;
    color: $main-color;
  }
}
.equipment{
  .equipment-item{
    position: relative;
    background-color: $main-color;
    cursor: pointer;
    width: 64px;
    height: 64px;
    &:hover .equipment-name{
      display: block;
    }
    .equipment-name{
      padding: 1rem;
      border: 2px solid #ebebeb;
      position: absolute;
      top: -25px;
      left: 0;
      transform: translateY(-100%);
      background: $white-color;
      z-index: 20;
      @include fontSize(12px);
      font-weight: 700;
      width: 250px;
      display: none;
      &:before{
        content: "";
        position: absolute;
        top: 100%;
        left: 14px;
        border-top: 15px solid $white-color;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
        z-index: 2;
      }
      &:after{
        content: "";
        position: absolute;
        top: 100%;
        left: 10px;
        border-top: 17px solid #ebebeb;
        border-left: 11.5px solid transparent;
        border-right: 11.5px solid transparent;
        z-index: 1;
      }
    }
  }
}
#product-description{
  background-color: #f1f1f1;

  .product-link{
    @include fontSize(14px);
    color: #464646;
    font-weight: 500;
  }

  .product-title{
    @include fontSize(24px);
    color: $main-color;
  }
  .nav-tabs{
    border: 0 solid transparent;
    .nav-item{
      border: 0 solid transparent;
    }
    .active{
      border: 0 solid transparent;
    }
    .nav-link{
      color: #464646;
      font-weight: 700;
      @include fontSize(12px);
      border-radius: 0!important;
    }
  }
  .tab-content{
    background-color: $white-color;
    border: 0;
    .specification-item{
      border-bottom: 2px solid #f1f1f1;
    }
  }
  .folder-button{
    background-color: #312f30;
    color: $white-color;
    font-weight: 700;
    @include fontSize(13px);
  }
  .instruction-button{
    background-color: #d7d7d7;
    color: $main-color;
    font-weight: 700;
    @include fontSize(13px);
  }
}
.search-products{
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  bottom: 0;
  height: calc(100vh - 220px);
  width: 100%;
  .product-image{
    height: 100px;
    width: 100px;
  }
}

#order{
  background-color: #f1f1f1;
  .white-background{
    background-color: $white-color;
  }
  .form-title{
    @extend .oswald;
    @include fontSize(24px);
  }
  .form-subtitle{
    @extend .oswald;
    @include fontSize(16px);
  }
}
.recommended{
  position: absolute;
  top: 0;
  right: 1rem;
  background-color: #34cfe1;
  color: $white-color;
  @include fontSize(12px);
  font-weight: 500;
}
.new_price{
  position: absolute;
  top: 0;
  right: 1rem;
  background-color: #ff482a;
  color: $white-color;
  @include fontSize(12px);
  font-weight: 500;
}