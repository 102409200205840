.navbar{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $white-color;
  z-index: 5000;
  border-bottom: 1px solid #e4e4e4;

  @media only screen and (max-width: 992px){
    overflow-y: auto;
    top: 0;
    bottom: 0;
    width: 100%;
    background: transparent;
    pointer-events: none;
  }
  .navbar-brand{
    img{
      height: 65px;
    }
  }
  .category-photo{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 150px;
  }
  .nav-container{
    position: relative;
    width: 300px;
    left: -300px;
    background: $white-color;
    min-height: 100vh;
    pointer-events: auto;
    overflow-y: auto;
  }
  .responsive-navbar-trigger{
    position: fixed;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    z-index: 999;
    background-color: #e4e4e4;
    cursor: pointer;
    pointer-events: initial;
  }
  .nav-link{
    color: #000;
    font-size: 12px;
    text-transform: uppercase;
    background-repeat: no-repeat;
    background-position: .25rem center;
    border-left: 1px solid #e4e4e4;
    font-weight: 500;
    position: relative;
    @media only screen and (min-width: 993px) {
      height: 75px;
    }
    &:hover{
      background-color: $hover-link-background;
      color: $white-color;
    }
  }
  .nav-item{
    position: relative;
  }
  .categories-menu{
    padding: 0;
    background-color: $white-color;
    border: 0;
    border-radius: 0;
    margin: 0;
    @media only screen and (min-width: 993px){
      overflow-y: auto;
      overflow-x: hidden;
      height: 50vh;
      width: 216px;
    }
    @media only screen and (max-width: 992px ){
      position: static;
      width: 100%;
    }
    .nav-item{
      border-left: 1px solid #e4e4e4;
    }
    .category-link{
      position: relative;
      border-bottom: 1px solid #e4e4e4;
      @include fontSize(12px);
      color: $main-color;
      .category-arrow{
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
      &:hover{
        background-color: transparent;
        color: $hover-link-background;
      }
    }
  }
  .overflow{
    @media only screen and (min-width: 993px){
      height: 75vh;
      overflow-y: auto;
    }
  }
  .subcategories-menu{
    position: fixed;
    top: 72px;
    border: 0;
    border-left: 1px solid #e4e4e4;
    border-radius: 0;
    margin: 0;
    width: 450px;
    @media only screen and (min-width: 993px){
      margin-left: 208px!important;
      left: auto;
      overflow-y: auto;
    }
    @media only screen and (max-width: 992px ){
      position: static;
      width: 100%;
      border-left: 0px solid #e4e4e4;
      background-color: #e4e4e4;
    }
    .sub-category-link{
      @include fontSize(12px);
      color: $main-color;
    }
  }
}